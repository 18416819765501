import React from 'react'
import { Link } from 'react-router-dom'
import {
    ContainerMBM, GlobalChanges, ScrollToTopOnMount,
} from '../../components';
import { FAQList,
    FAQListItem,
    FullTextSection,
    GrayTextSection,
    ImageSection,
    ListItemTitleDescription,
    Row, SectionHeadline,
    UndecoratedList
} from '../components/atoms';
import Footer from '../../Footer';

const itineraryDesignProcessHeadline = "Itinerary Design Process"

const itineraryDesignProcessList = [
    {
        summary: "Connect",
        description: ["Submit a new travel inquiry ", ". Once we’ve reviewed your inquiry, we will reach out to learn more about you and your travel preferences."],
        hasLink: <Link to="/contact">here</Link>
    },
    {
        summary: "Plan",
        description: "Now it’s time to bring everything to life! We work with our on-the-ground experts to create an itinerary tailored to your unique needs and interests. Your personalized itinerary will include hotel matches, transportation logistics, activities, restaurant recommendations, costing, etc."
    },
    {
        summary: "Book",
        description: "Once we’ve aligned on your travel plans, KGTC confirms all itinerary details and will be in touch with any critical information. Thanks to our travel industry partnerships and memberships to invite-only travel clubs we are able to add exclusive perks and amenities to your reservations. You’ll receive a personalized mobile app which aggregates all of your trip plans, confirmations, and ground contacts for easy reference."
    },
    {
        summary: "Experience",
        description: "Bon Voyage! While you are away, we work behind the scenes, checking to make sure things are flowing seamlessly and that every trip we send you on is the trip of a lifetime. Oh and don’t forget to tag @kgtravelclub in your photos!"
    },
    {
        summary: "Reflect",
        description: "Once you’ve arrived home, we will reach out to get your feedback on each aspect of your trip. The more feedback we receive, the better we are able to personalize your future trips."
    }
]

const faqList = [
    {
        question: "I LOVE TRIP PLANNING. WHY WOULD I USE KGTC INSTEAD OF DOING IT MYSELF?",
        answerType: "description-summary-list",
        answer: [
            "We hear it all the time. While many travelers love the inspirational process of planning their own trips, there’s many reasons why you should leave the heavy lifting to us:",
            {
                description: "You can’t VIP yourself!",
                summary: "We have access to VIP rates not available to the general public. When booking you a hotel stay, we always work with the hotel’s sales team to VIP your reservation, personalize your stay, and add extra perks like upgrades, free breakfast, and hotel credits. Some of the world’s best travel suppliers will only sell to travel advisors and won’t even work with the general public. We even have VIP access to things like sold out restaurant reservations and private after hours tours at museums."
            },
            {
                description: "Planning the logistics can be hard",
                summary: "Did you know the average person spends 10-30 hours planning a vacation? Dreaming up things to do and see is the fun part but putting it all together – not so much. Leave the logistics to us - we can take all your ideas, from friends’ recommendations to landmarks spotted on Instagram, and piece it together in a highly detailed itinerary."
            },
            {
                description: "Cut through the noise",
                summary: "We tap into an extensive network of on-the-ground experts for the inside scoop on the latest and greatest. What your friend recommended from their trip last year may no longer be relevant – the world of travel is far from stagnant.  And don’t get us started on these anonymous internet review sites."
            },
            {
                description: "Peace of Mind",
                summary: "Travel is an investment – with KGTC you’ll have peace of mind that you are spending your money wisely and having the best travel experience possible. While you are traveling, we always have your back when the unexpected happens."
            },
        ]
    },
    {
        question: "HOW IS YOUR HOTEL MATCHMAKING SERVICE FREE?",
        answer: "KGTC is compensated with sales commission by the hotels we book. 95% of hotels pay sales commission so we are able to keep this service complimentary to you and you can rest assured we are recommending hotels with your best interests at heart.",
        answerType: "normal"
    },
    {
        question: "DO YOU REQUIRE A MINIMUM SPEND FOR TRIPS?",
        answer: "We do not have a minimum spend and pricing is destination dependent but we do specialize in luxury travel. The hotels we book generally start at $1,000/night. The itineraries we book normally begin at $2,000/night for two people, including hotels.",
        answerType: "normal"
    },
    {
        question: "WHAT’S THE CATCH?",
        answer: "Like, the catch of the day? JK. We understand why you’d ask but we can assure you there is none.\n\n  ‍Here’s a peek behind the scenes: Hotels love working with us because we put a lot of effort into recommending the right destination for each client and ensuring they are well taken care of. Happy guests are repeat guests and there’s nothing the travel industry values more than loyalty. That’s why hotels let us offer our clients special perks like upgrades and hotel credits – so they are incentivized to book through us verse online travel portals. Guests that book through online travel portals like Expedia or Booking.com are categorized as having no loyalty and only price shopping, so hotels assign them to the worst rooms and do the bare minimum for their stay. This same concept applies to airlines, cruises, tour operators, etc.",
        answerType: "normal"
    },
    {
        question: "HOW INVOLVED CAN I BE IN THE PLANNING?",
        answer: "As involved as you’d like! Some clients want to do nothing more than pack. Some clients send us detailed itineraries so that we can give feedback, handle the logistics, and add our perks. Since our itineraries are so customized, we welcome feedback and collaborate on the itinerary until it is perfect for you.",
        answerType: "normal"
    },
    {
        question: "WHAT TYPES OF TRIPS CAN YOU PLAN?",
        answer: "We do it all – single night hotel bookings, honeymoons, cruises, corporate incentive trips, big family reunions, safaris, wedding room blocks.. Our only limitation is that we work solely with high end hotels & travel suppliers. We have implemented these restrictions on our services in order to mitigate the risks of something going wrong while you are traveling.",
        answerType: "normal"
    },
    {
        question: "WHAT IS THE BEST WAY TO COMMUNICATE DURING THIS PROCESS?",
        answer: <>During the planning process, we will primarily communicate through email. This allows us to keep track of anything that is discussed since each trip requires so much detail. We are, however, always available to chat on the phone, especially during the beginning stages of planning when the big decisions are made and you need extra guidance. Your itinerary design fee includes up to three planning phone calls and additional phone calls are available at a fee.<br /><br /> While we do provide the plans for the trip, you are responsible for handling any adjustments to your bookings while in travel. With our full itinerary design service you will have a team on the ground with you assisting with any changes, questions, or emergencies. Most local teams communicate via WhatsApp. With our hotel only booking service, you would communicate directly with your hotel. If while traveling you are experiencing service issues or not getting the answers you need, then we are always available as back-up.</>,
        answerType: "normal"
    },
    {
        question: "WHAT IF YOU DESIGN AN ITINERARY AND THEN I DECIDE I WANT TO GO IN A DIFFERENT DIRECTION?",
        answer: "Since the work we do involves custom trip planning, the time spent on your trip is uniquely yours. Starting from scratch is absolutely possible, but will require another itinerary design fee.",
        answerType: "normal"
    },
    {
        question: "WHAT IF I NEED TO CANCEL MY TRIP?",
        answer: "Cancellation costs vary by supplier. These policies will always be available to you before you confirm. In the event of cancelation, KGTC will negotiate on your behalf but does not have control over the suppliers policies. KGTC itinerary design fees are non-refundable.",
        answerType: "normal"
    },
    {
        question: "HOW DOES PAYMENT WORK?",
        answer: "Payment policies and schedules vary by supplier. The KGTC itinerary design fee is due before we send the preliminary itinerary.",
        answerType: "normal"
    },
    {
        question: "DO YOU RECOMMEND TRAVEL INSURANCE?",
        answer: "Travel insurance is always recommended. If you are interested in travel insurance ask us about it before confirming your trip since many insurance policies require that you purchase travel insurance at the same time you purchase your trip. While we are not insurance experts, we are happy to provide guidance.",
        answerType: "normal"
    },
    {
        question: "CAN MY TRIP ITINERARY BE ITEMIZED?",
        answer: "With our full itinerary design service, we provide a package price and are contractually unable to break down pricing. If you wish to amend your itinerary, we will reprice your total trip cost.",
        answerType: "normal"
    },
    {
        question: "I HAVE ELITE STATUS AT MY PREFERRED HOTEL BRAND – WHY WOULD I BOOK WITH KGTC?",
        answer: "KGTC Perks are combinable with hotel loyalty programs so you’ll get even MORE benefits than usual and double your chances of getting that upgrade. For clients that don’t have status at hotels – we often compare booking through KGTC to becoming an automatic Elite Member at the hotel you’re checking in to. ",
        answerType: "normal"
    },
    {
        question: "ARE YOU ABLE TO BOOK TRAVEL USING POINTS?",
        answer: "We are unable to book travel using points. If you would like to book using points, please let us know in advance.",
        answerType: "normal"
    },
    {
        question: "HOW IS BOOKING HOTELS THROUGH KGTC DIFFERENT FROM BOOKING HOTELS THROUGH AMEX?",
        answer: "AmEx does offer similar hotel rates but is essentially a call center. You will not receive even close to the same level of expertise, personalization, and attention as you do with KGTC.",
        answerType: "normal"
    },
    {
        question: "WHERE CAN I FIND KGTC TESTIMONIALS?",
        answer: ["Visit our Yelp page by clicking ", <a href="https://www.yelp.com/biz/kg-travel-club-new-york">here</a>],
        answerType: "has-link"
    },
    {
        question: "HAVE MORE QUESTIONS?",
        answer: ["E-mail us at ", <a href="mailto:info@kgtravelclub.com">info@kgtravelclub.com</a>],
        answerType: "has-link"
    },
]

const itineraryDesignProcessImage = require('./images/cabanas_by_water.jpeg')

const ItineraryDesignProcess = () =>
    <Row>
        <ImageSection image={itineraryDesignProcessImage} side={'left-large'} mobileHeight={'12rem'} />

        <GrayTextSection side={'right-small'}>
            <SectionHeadline>
                {itineraryDesignProcessHeadline}
            </SectionHeadline>
            <UndecoratedList>
                {
                    itineraryDesignProcessList.map(e =>
                        <ListItemTitleDescription summary={e.summary} description={e.description} hasLink={e.hasLink} />)
                }
            </UndecoratedList>
        </GrayTextSection>
    </Row>


const feeStructureHeadline = "Fee Structure"
const feeStructureList = [
    {
        summary: "Itinerary Design",
        description: "$400 per adult for up to two weeks of travel. Children under 18 are $150."
    },
    {
        summary: "Hotel Matchmaking & Bookings",
        description: "Complimentary."
    },
    {
        summary: "Cruise Matchmaking & Bookings",
        description: "Complimentary."
    },
    {
        summary: "Corporate & Group Travel",
        description: "Reach out for custom pricing."
    },
]

const feeStructureImage = require('./images/fee-structure.jpg')
const FeeStructure = () =>
    <Row>
        <ImageSection image={feeStructureImage} side={'right-large'} mobileHeight={'12rem'} />

        <GrayTextSection side={'left-small'}>
            <SectionHeadline>
                {feeStructureHeadline}
            </SectionHeadline>
            <UndecoratedList>
                {
                    feeStructureList.map(e =>
                        <ListItemTitleDescription summary={e.summary} description={e.description} />)
                }
            </UndecoratedList>
        </GrayTextSection>
    </Row>


const FAQ = () =>
    <Row>
        <FullTextSection>
            <SectionHeadline>FAQ</SectionHeadline>
            <FAQList>
                {
                    faqList.map(e =>
                        <FAQListItem question={e.question} answer={e.answer} answerType={e.answerType} />)
                }
            </FAQList>

        </FullTextSection>
    </Row>

const HowItWorks = () =>
    <ContainerMBM>
        <GlobalChanges />
        <ScrollToTopOnMount />
        <ItineraryDesignProcess />
        <FeeStructure />
        <FAQ />
        <Footer />
    </ContainerMBM>

export default HowItWorks
